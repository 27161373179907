import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../styles/demandbase.css"
import Slider from "react-slick";
import Shapefour from "../images/bannerElement/white-design-element-demandbase.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import DemandBaseRightRoundOne from "../images/DemandBaseRightRoundOne.png"; 
import DemandBaseRightRoundTwo from "../images/DemandBaseRightRoundTwo.png"; 
import AlignSalesandMarketing from "../images/icons/Demandbase/Align-sales-and-marketing-efforts-for-a-unified-approach.svg" 
import HigherQualityProspects from "../images/icons/Demandbase/Higher-quality-prospects-in-your-sales-funnel.svg" 
import ImprovedConversion1 from "../images/icons/Demandbase/Improved-conversion-rates1.svg" 
import ImprovedConversion2 from "../images/icons/Demandbase/Improved-conversion-rates2.svg" 
import OptimizedMarketing from "../images/icons/Demandbase/Optimized-marketing-spend.svg" 

export default function AccountBasedMarketing(){
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Demandbase | Position²</title>
          <script src="https://use.fortawesome.com/23bb216b.js"></script>
          <body className="home" />
        </Helmet>
        <Layout>
          <section id="DemandbaseBanner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Demandbase + Position<sup>2</sup></h1>
                    <p>Maximize the return on your marketing investment</p>
                    <a className="button" id="3dsolutionvideo" href="/contact-us/">Get in touch</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="DemandbasePageIntro">
            <div class="container">
              <h2>Maximising your marketing ROI</h2>
              <p>
                In today's competitive B2B landscape, growing revenue is
                increasingly challenging. Traditional sales and marketing
                tactics are losing effectiveness as buyers become numb to
                outreach. To succeed, you need a targeted approach that delivers
                the right message to the right prospect at the right time.
              </p>
            </div>
          </section>
          <section id="DemandbaseTextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Buyer intent: the key to personalized messaging</h2>
                  <p>
                    With nearly 70% of B2B buyers finding and consuming content
                    directly from vendor websites, identifying buyer intent is
                    crucial for delivering personalized messaging. Demandbase's
                    solution is at the forefront of this, providing intent and
                    engagement data on your target accounts and enabling you to
                    reach the right buyers.
                  </p>
                </div>
                <div class="img-wrap">
                  <img alt="Copy write" src={DemandBaseRightRoundOne} />
                </div>
              </div>
            </div>
          </section>
          <section id="DemandbaseTextImageSection" class="section-greybg">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Our expertise, your success</h2>
                  <p>
                    As a certified Demandbase partner and client, our team is
                    well-equipped to support you throughout your journey. From
                    setup and integration into your tech stack to the creation
                    and execution of advertising campaigns, we're here to help
                    you maximize your marketing ROI.
                  </p>
                  <p class="list-header">Focus areas:</p>
                  <ul class="bullet-point">
                    <li>Expert guidance for your ABM/ABX journey</li>
                    <li>
                      Enhancing your team's capabilities with our certified
                      strategy, marketing, and creative teams
                    </li>
                    <li>
                      Data-driven approach to optimize campaign performance
                    </li>
                  </ul>
                </div>
                <div class="img-wrap">
                  <img alt="Copy write" src={DemandBaseRightRoundTwo} />
                </div>
              </div>
            </div>
          </section>
          <section id="DemandbasePagesecondIntro">
            <div class="container">
              <h2>Overcoming common challenges</h2>
              <p>
                We help you tackle the most pressing challenges hindering your
                revenue growth and marketing efficiency.
              </p>
              <ul class="bullet-point">
                <li>Missing growth targets</li>
                <li>Prospects stuck in the mid-funnel</li>
                <li>Uncontrolled marketing budgets and declining ROI</li>
              </ul>
            </div>
          </section>
          <section id="DemandbaseWeDeliver">
            <div className="container">
              <h2>Elevate your ABM strategy</h2>
              <p>
                Partner with Position<sup>2</sup> and Demandbase to take your
                ABM strategy to new heights. Let us help you deliver
                personalized messaging, optimize your marketing spend, and drive
                revenue growth.
              </p>
              <div className="horizontalImageIconSec">
                <div className="moduleWrap">
                  <div className="eachModule">
                    <div className="icons">
                      <img src={OptimizedMarketing} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <p>Optimized Marketing Spend</p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={ImprovedConversion2} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <p>Improved Conversion Rates</p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={HigherQualityProspects}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <p>Higher Quality Prospects in Your Sales Funnel</p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={AlignSalesandMarketing}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <p>
                        Align Sales and Marketing Efforts for a Unified Approach
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="Demandbasecontactus-section">
            <div class="container">
              <h2>Schedule a consultation to discuss your ABM goals</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">Get in touch</a>
            </div>
          </section>
        </Layout>
      </>
    )
  }